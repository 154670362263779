// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'stylesheets/application.scss'
import 'bootstrap-duallistbox/src/jquery.bootstrap-duallistbox.js'
import 'bootstrap-duallistbox/src/bootstrap-duallistbox.css'

//import '../js/bootstrap_js_files.js'

import "cocoon";

//require("stylesheets/application.scss")
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")



const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
//require.context('../images', true)
//const images = require.context('../images', true)

//Rails.start()
//Turbolinks.start()
//ActiveStorage.start()
